export const theme = {
    token: {
        colorPrimary: '#3A84FF',
        borderRadius: 0,
        fontSize: 12,
        wireframe: false,
        colorInfo: '#3A84FF',
        colorBorder: '#DFE0E5',
        colorTextBase: '#63656e',
        colorTextPlaceholder: '#979BA5'
    }
}
